import router from 'next/router';
import { handleErrorPageError } from '../../store/reducers/error';

export const useHandleError = (
    dispatch,
    statusCode = 404,
    serverResponse = null,
    langUrl = '',
    primaryKey = ''
) => {
    // prevent loop redirecting in case of multi errors: saving and rendering first error in queue
    // force handle 'badLocale' error
    if (router.router.route !== '/[lang]/error' || primaryKey === 'badLocale') {
        const lang = langUrl || router.query.lang || 'en';
        const payload = serverResponse ? { serverResponse, status: statusCode } : { status: statusCode };
        dispatch(handleErrorPageError(payload));
        let params = `code=${statusCode}`;
        if (serverResponse) {
            params = `${params}&failed_url=${serverResponse.url}`;
        }

        //reload the app on error page to show with correct status
        window.location.href = `/${lang}/error?${params}`;
    }
};

export const consoleError = (error) => error ?
    console.error(`The error occurred on '${error.config?.url}': ${error.status} ${error.statusText}`) :
    console.error('The error occurred');
