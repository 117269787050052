const GET_ERROR_PAGE_ERROR = 'GET_ERROR_PAGE_ERROR';

export const initialState = {
    errorPageError: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_ERROR_PAGE_ERROR:
            return {
                ...state,
                errorPageError: action.payload
            };
        default:
            return state;
    }
};

export const handleErrorPageError = (payload) => ({
    type: GET_ERROR_PAGE_ERROR,
    payload
});
