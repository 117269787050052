import { combineReducers } from 'redux';
import gameData from './gameData';
import language from './language';
import cms from './cms';
import bootstrap from './bootstrap';
import error from './error';
import registration from './registration';
import user from './user';
import global from './global';
import bank from './bank';
import bonus from './bonus';

export default combineReducers({
    gameData,
    language,
    cms,
    bootstrap,
    error,
    registration,
    user,
    bank,
    global,
    bonus
})
