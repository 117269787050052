import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
export const { apiUrl } = publicRuntimeConfig;
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { useHandleError } from '../components/utils/useHandleError';
import { finishSession } from "../store/reducers/user";

export const AppStore = {
    store: {}
};

const exceptionErrorUrls = [
    'player/register',
    'player/login',
    'player/forgot-password',
    'player/change-password',
    'player/document',
    'payment',
    'transaction/status',
    'wallet/withdrawal-request',
    'wallet/transactions',
    'wallet/withdrawal',
    'bonus/',
];

const fullErrorDataUrls = [
    'payment/',
    'transaction/status',
    'player/register',
    'player/information',
    '/cms/slider',
    'statistics/visit',
    'player/logout',
    '/launch',
    'bootstrap/',
];

export const http = axios.create({
    baseURL: apiUrl
});

http.interceptors.request.use(
    async (config) => {
        config.headers['Content-Type'] = 'application/json';
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const res = error.response || {};
        const status = res.status;
        const url = res.config?.url;
        const isFullErrorData = fullErrorDataUrls.find(item => url.includes(item));
        const errorData = isFullErrorData ? res : res.data;

        if (status) {
            if (status === 401) {
                AppStore.store.dispatch(finishSession());
            } else {
                const sentryError = res.data ? JSON.stringify(res.data) : res.statusText;
                //Send error to Sentry if not 401
                Sentry.captureException(new Error(`Request '${url}' failed with status code ${status}: ${sentryError}`));
                const payload = res.config ? { ...res.config, status } : { status };
                if (!exceptionErrorUrls.find(item => url.includes(item))) {
                    useHandleError(AppStore.store.dispatch, status, payload);
                }
            }
        }
        return Promise.reject(errorData);
    }
);
