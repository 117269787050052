import { bootstrapRequest } from '../../workers/bootstrap';
import { translations, translationsLoading } from './language';
import { contentFromCms, allCmsContent } from './cms';
import { createCountrySelect } from './registration';
import { useLoadingPercent } from "../../components/utils/useLoadingPercent";
import { setLimits, getPaymentCmsContent } from "./bank";
import { consoleError } from '../../components/utils/useHandleError';

const DETECT_DEVICE = 'DETECT_DEVICE';
const REQUEST_BOOTSTRAP_SUCCESS = "REQUEST_BOOTSTRAP_SUCCESS";
const REQUEST_BOOTSTRAP_FAILED = "REQUEST_BOOTSTRAP_FAILED";
const SET_HEADER_HEIGHTS = "SET_HEADER_HEIGHTS";
const ON_LOADING = "ON_LOADING";
const SET_TOP_HEIGHT = "SET_TOP_HEIGHT";

export const initialState = {
    isBootstrapLoaded: false,
    isBootstrapFailed: false,
    isMobile: false,
    isTablet: false,
    headerHeights: {
        desktop: 70,
        tablet: 50,
        mobile: 40
    },
    loadingProgress: 25, //define start progress value on init loading
    topHeight: 0,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case REQUEST_BOOTSTRAP_SUCCESS:
            return {
                ...state,
                isBootstrapLoaded: true,
            };
        case REQUEST_BOOTSTRAP_FAILED:
            return {
                ...state,
                isBootstrapFailed: true,
            };
        case DETECT_DEVICE:
            return {
                ...state,
                isMobile: action.payload[0].mobile,
                isTablet: action.payload[1].tablet,
            };
        case SET_HEADER_HEIGHTS:
            return {
                ...state,
                headerHeights: action.payload,
            };
        case ON_LOADING:
            const currentProgress = state.loadingProgress + action.payload;
            const loadingProgress = currentProgress > 100 ? 100 : currentProgress;
            return {
                ...state,
                loadingProgress,
            };
        case SET_TOP_HEIGHT:
            return {
                ...state,
                topHeight: action.payload,
            };
        default:
            return state
    }
}

export const detectDevice = (payload) => ({
    type: DETECT_DEVICE,
    payload
});

export const onLoading = (payload) => ({
    type: ON_LOADING,
    payload
});

export const getBoostrap = (payload, isUserPage) => {
    const bootstrapCallSuccess = () => ({
        type: REQUEST_BOOTSTRAP_SUCCESS
    });
    const bootstrapCallFailed = () => ({
        type: REQUEST_BOOTSTRAP_FAILED
    });
    return dispatch => {
        dispatch(translationsLoading());
        bootstrapRequest(payload)
            .then(res => {
                const { data } = res.data;
                if (data) {
                    dispatch(bootstrapCallSuccess());
                    dispatch(onLoading(useLoadingPercent(res.config.headers['Authorization'], 'bootstrap', isUserPage)));
                    dispatch(translations({data: data['translations'][payload]}, payload));
                    dispatch(contentFromCms({data: data['application-information']}));
                    dispatch(allCmsContent({data: data['cms-pages'][payload]}));
                    dispatch(createCountrySelect({data: data['application-information']}));
                    dispatch(setLimits({
                        minimumDeposit: data['application-information'].minimum_deposit,
                        minimumWithdrawal: data['application-information'].minimum_withdrawal,
                        maximumWithdrawal: data['application-information'].maximum_withdrawal
                    }));
                    dispatch(getPaymentCmsContent({data: data['cms-pages'][payload]}));
                } else throw { emptyData: true };
            })
            .catch((err) => {
                if (!err || err.emptyData) {
                    window.location.href = `/${payload}/error?code=500&failed_url=bootstrap/${payload}`;
                }
                dispatch(bootstrapCallFailed());
                consoleError(err);
            })
    }
};

export const setHeaderHeights = (payload) => ({
    type: SET_HEADER_HEIGHTS,
    payload
});

export const setTopHeight = (payload) => ({
    type: SET_TOP_HEIGHT,
    payload
});
