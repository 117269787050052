import { bonusRequest, bonusCancelAction, bonusClaimAction } from '../../workers/bonus';
import { useToken } from "../../components/utils/useToken";

const ADD_BONUS_LOADING = 'ADD_BONUS_LOADING';
const ADD_BONUS_SUCCESS = 'ADD_BONUS_SUCCESS';
const ADD_BONUS_FAIL = 'ADD_BONUS_FAIL';
const ADD_BONUS_STATUS = 'ADD_BONUS_STATUS';
const REMOVE_BONUS_LOADING = 'REMOVE_BONUS_LOADING';
const REMOVE_BONUS_SUCCESS = 'REMOVE_BONUS_SUCCESS';
const REMOVE_BONUS_FAIL = 'REMOVE_BONUS_FAIL';
const CLEAN_REMOVE_BONUS_ERROR = 'CLEAN_REMOVE_BONUS_ERROR';
const GET_BONUS_CONTENT_LOADING = 'GET_BONUS_CONTENT_LOADING';
const GET_BONUS_CONTENT_SUCCESS = 'GET_BONUS_CONTENT_SUCCESS';
const GET_BONUS_CONTENT_FAIL = 'GET_BONUS_CONTENT_FAIL';
const GET_FREEROUNDS_GAMES = 'GET_FREEROUNDS_GAMES';

export const initialState = {
    bonusTabs: ['offered', 'in-progress', 'waiting'],
    bonus: {
        offered: [],
        claimed: []
    },
    isLoading: false,
    bonusesOfferedPerPage: 3,
    bonusesWaitingPerPage: 6,
    bonusListError: false,
    addBonusLoadingId: null,
    addBonusStatus: '',
    claimedBonusName: '',
    removeBonusLoadingId: null,
    removeBonusStatus: '',
    removedBonusName: '',
    freeRoundsGames: []
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_BONUS_CONTENT_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case GET_BONUS_CONTENT_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            const offeredBonuses = action.payload.data.filter(item => item.status !== 'claimed');
            const claimedBonuses = action.payload.data.filter(item => item.status === 'claimed');
            return {
                ...state,
                bonus: {
                    offered: offeredBonuses,
                    claimed: claimedBonuses
                },
                isLoading: false
            };
        case ADD_BONUS_LOADING:
            return {
                ...state,
                addBonusLoadingId: action.payload,
            };
        case ADD_BONUS_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            const isFreeRound = action.payload.data.type === "free_rounds";
            return {
                ...state,
                addBonusLoadingId: null,
                addBonusStatus: isFreeRound ? 'free_rounds_claimed' : 'success',
                claimedBonusName: action.payload.data.name,
            };
        case ADD_BONUS_FAIL:
            return {
                ...state,
                addBonusLoadingId: null,
                addBonusStatus: 'fail',
                claimedBonusName: '',
            };
        case ADD_BONUS_STATUS:
            return {
                ...state,
                addBonusStatus: action.payload,
                claimedBonusName: '',
            };
        case REMOVE_BONUS_LOADING:
            return {
                ...state,
                removeBonusLoadingId: action.payload,
            };
        case REMOVE_BONUS_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            return {
                ...state,
                removeBonusLoadingId: null,
                removeBonusStatus: 'success',
                removedBonusName: action.payload.data.name,
            };
        case REMOVE_BONUS_FAIL:
            return {
                ...state,
                removeBonusLoadingId: null,
                removeBonusStatus: 'fail',
                removedBonusName: '',
            };
        case CLEAN_REMOVE_BONUS_ERROR:
            return {
                ...state,
                removeBonusStatus: '',
                removedBonusName: '',
            };
        case GET_FREEROUNDS_GAMES:
            return {
                ...state,
                freeRoundsGames: action.payload
            };
        default:
            return state
    }
}

export const getContentForBonus = (token) => {
    const loading = () => ({
        type: GET_BONUS_CONTENT_LOADING,
    });
    const success = (payload) => ({
        type: GET_BONUS_CONTENT_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_BONUS_CONTENT_FAIL,
    });
    return dispatch => {
        dispatch(loading());
        bonusRequest(token)
            .then(res => dispatch(success(res.data)))
            .catch(() => dispatch(fail()))
    }
};

export const changeAddBonusStatus = (payload) => ({
    type: ADD_BONUS_STATUS,
    payload
});

export const getFreeRoundsGames = (payload) => ({
    type: GET_FREEROUNDS_GAMES,
    payload
});

export const cleanRemoveBonusError = () => ({
    type: CLEAN_REMOVE_BONUS_ERROR,
});

export const postBonusAction = (id, callback) => {
    const loading = (payload) => ({
        type: ADD_BONUS_LOADING,
        payload
    });
    const success = (payload) => ({
        type: ADD_BONUS_SUCCESS,
        payload
    });
    const fail = () => ({
        type: ADD_BONUS_FAIL,
    });
    return dispatch => {
        const body = {
            'bonusIds': [id]
        }
        dispatch(loading(id));
        bonusClaimAction(body)
            .then(res => {
                dispatch(success(res.data));
                dispatch(getContentForBonus());
                callback();
            })
            .catch(() => dispatch(fail()));
    }
};

export const removeBonusAction = (id, callback) => {
    const loading = (payload) => ({
        type: REMOVE_BONUS_LOADING,
        payload
    });
    const success = (payload) => ({
        type: REMOVE_BONUS_SUCCESS,
        payload
    });
    const fail = () => ({
        type: REMOVE_BONUS_FAIL,
    });
    return dispatch => {
        dispatch(loading(id));
        bonusCancelAction(id)
            .then(res => {
                dispatch(success(res.data));
                dispatch(getContentForBonus());
                callback();
            })
            .catch(() => dispatch(fail()));
    }
};
