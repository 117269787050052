const UPDATE_LANG = 'UPDATE_SITE_LANGUAGE';
const ON_REQUEST = "REQUEST_IN_PROCESS";
const REQUEST_LANG_FAIL = 'REQUEST_LANG_FAIL';

export const initialState = {
    loading: false,
    failRequest: false,
    language: 'en',
    otherLang: ['en'],
    translations: {},
    isTranslationsLoaded: false,
};

export default function Language (state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_LANG:
            return {
                ...state,
                loading: false,
                failRequest: false,
                translations: action.res.data,
                isTranslationsLoaded: true
            };
        case ON_REQUEST:
            return {
                ...state,
                loading: true,
                failRequest: false,
            };
        case REQUEST_LANG_FAIL:
            return {
                ...state,
                loading: false,
                failRequest: true,
            };
        default:
            return state
    }
}

export const translations = (res, payload) => ({
    type: UPDATE_LANG,
    res,
    payload
});

export const translationsLoading = () => ({
    type: ON_REQUEST
});

export const translationsFailed = () => ({
    type: REQUEST_LANG_FAIL
});
