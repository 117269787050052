import { http } from './config'

export const csrfRequestReistration = () => {
    return http.get(`player/register/csrf/token`, { withCredentials: true });
};

export const registrationRequest = form => {
    return http.post(`player/register`, JSON.stringify(form), { withCredentials: true });
};

export const registrationSocialRequest = form => {
    return http.post(`socialplayer/register`, JSON.stringify(form), { withCredentials: true });
};

export const csrfRequestSocialReistration = () => {
    return http.get(`socialplayer/register/csrf/token`, { withCredentials: true });
};
