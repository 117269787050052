import { http } from "./config";

export const bonusRequest = () => {
    return http.get(`bonuses`)
};

export const bonusClaimAction = ( body ) => {
    return http.post(`bonus/claim-bonuses`, body);
};

export const bonusCancelAction = ( id ) => {
    return http.post(`bonus/${id}/cancel`);
};
