const SET_ACTIVE_MODAL_ID = 'SET_ACTIVE_MODAL_ID';
const REMOVE_ACTIVE_MODAL_ID = 'REMOVE_ACTIVE_MODAL_ID';

export const initialState = {
    loggedModalsId: ['registration_success', 'deposit', 'withdraw-modal'],
    unLoggedModalsId: ['registration', 'login', 'forgot-password'],
    activeModalId: ''
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ACTIVE_MODAL_ID:
            return {
                ...state,
                activeModalId: action.payload
            };
        case REMOVE_ACTIVE_MODAL_ID:
            return {
                ...state,
                activeModalId: ''
            };
        default:
            return state
    }
}

export const setActiveModalId = (payload) => ({
    type: SET_ACTIVE_MODAL_ID,
    payload
});

export const removeActiveModalId = () => ({
    type: REMOVE_ACTIVE_MODAL_ID
});
