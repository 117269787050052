import { http } from './config';

export const userInformationRequest = () => {
    return http.get('player/information')
};

export const csrfRequest = () => {
    return http.get(`player/login/csrf/token`, { withCredentials: true });
};

export const loginRequest = (form, param) => {
    return http.post(`player/login${param}`, JSON.stringify(form), { withCredentials: true });
};

export const logoutUser = () => {
    return http.post(`player/logout`);
};

export const forgotPasswordRequest = form => {
    return http.post(`player/forgot-password`, JSON.stringify(form));
};

export const updateMarketingAllowedRequest = (data) => {
    return http.put('player/information', JSON.stringify(data));
};

export const changePasswordRequest = (form)=> {
    return http.post('player/change-password', JSON.stringify(form));
};

export const fetchUserDocuments = () => {
    return http.get('player/documents');
};

export const uploadDocumentById = (id, formData) => {
    return http.post(`player/document/${id}`, formData);
};
