
export const useLoadingPercent = (token, call, isUserPage) => {
    // only 3 requests impact on page loading
    if (token) {
        switch (call) {
            case 'bootstrap':
                return isUserPage ? 40 : 20;
            case 'games':
                return isUserPage ? 0 : 40;
            case 'userInformation':
                return isUserPage ? 35 : 15;
            default:
                return 0;
        }
    } else {
        switch (call) {
            case 'bootstrap':
                return isUserPage ? 75 : 35;
            case 'games':
                return isUserPage ? 0 : 40;
            case 'userInformation':
                return isUserPage ? 0 : 0;
            default:
                return 0;
        }
    }
};
