import { http } from './config'

export const gameRequest = () => {
    return http.post(`games`, {
        device: 'desktop',
    })
};

export const getGameUrlRequest = (lang, id, body) => {
    return http.post(`${lang}/game/${id}/launch`, body);
};
